import React from "react";
import styled from "styled-components";
import PortfolioCard from "../Index/PortfolioBlock/components/PortfolioCard";
import BlockContainer from "../Core/Containers/BlockContainer";
import ContentContainer from "../Core/Containers/ContentContainer";
import breakpoints from "../../styles/breakpoints";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;

  @media (min-width: ${breakpoints.sm}) {
    row-gap: 60px;
  }

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const CardContainer = styled.div`
  :first-of-type {
    grid-column: 1 / -1;
  }

  :nth-child(2) {
    grid-column: 1 / -1;
  }

  :nth-child(3) {
    grid-column: 1 / -1;
  }

  @media (min-width: ${breakpoints.md}) {
    :first-of-type {
      grid-column: auto;
    }

    :nth-child(2) {
      grid-column: auto;
    }

    :nth-child(3) {
      grid-column: auto;
    }
  }
`;

const PortfolioGrid = ({ works }) => {
  let sortedWorks = works;
  if (works) {
    sortedWorks = works.sort((a, b) => {
      const aOrder = a.order ? a.order : 999;
      const bOrder = b.order? b.order : 999;

      return aOrder - bOrder
    });
  }
  return (
    <BlockContainer block>
      <ContentContainer>
        <Grid>
          {sortedWorks.map((work, index) => {
            return (
              <CardContainer key={index} >
                <PortfolioCard
                  {...work}
                  isLink
                />
              </CardContainer>
            );
          })}
        </Grid>
      </ContentContainer>
    </BlockContainer>
  );
};

export default PortfolioGrid;
