import React, { useState } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";
import ContentContainer from "../Core/Containers/ContentContainer";
import breakpoints from "../../styles/breakpoints";
import colors from "../../styles/colors";
import Slider from "react-slick";
import { useLocation } from "@reach/router";

const IntroContainer = styled.div`
  margin: 24px auto 40px;
  overflow: hidden;
  @media (min-width: ${breakpoints.md}) {
    margin: 100px auto 48px;
  }
`;

const Block = styled.div`
  max-width: ${breakpoints.xxl};
  margin: auto;
`;
const TitleSection = styled.div`
  margin-bottom: 40px;
`;

const Categories = styled.div`
  overflow: visible;

  a {
    color: ${colors.greyMedium};
    background: ${colors.greyLight};
    text-decoration: none;
    padding: 8px 16px;
    border-radius: 30px;
  }

  .active {
    background: ${colors.greyDark};
    color: ${colors.white};
  }
`;

const CategoryLink = styled(Link)``;

const StyledSlider = styled(Slider)`
  .slick-list {
    overflow: visible;
  }
  .slick-track {
    margin-left: 26px;
  }
  .slick-slide {
    padding-right: 12px;
    :last-of-type {
      padding-right: 0;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    .slick-track {
      margin-left: 38px;
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    .slick-track {
      margin-left: 76px;
    }
  }
`;

const getInitialSludeIndex = (location) => {
  let slideIndex = 0;
  if (location) {
    const searchParams = location.search.replace("?q=", "");
    if (searchParams) {
      slideIndex = decodeURI(searchParams);
    }
  }

  return Number(slideIndex);
};

const IntroBlock = ({ heading }) => {
  const location = useLocation();
  const [slideIndex, setSlideIndex] = useState(getInitialSludeIndex(location));
  const { categories, indexPage } = useStaticQuery(
    graphql`
      query {
        indexPage: contentfulPortfolioPage(slug: { eq: "our-work" }) {
          headingText
        }
        categories: allContentfulCategory {
          nodes {
            category
            slug
          }
        }
      }
    `
  );

  const getActiveStatus = (slug) => {
    if (slug === "all") {
      return (
        location.pathname === "/our-work" || location.pathname === "/our-work/"
      );
    }
    return location.pathname.includes(slug);
  };

  const settings = {
    variableWidth: true,
    infinite: false,
    arrows: false,
    dots: false,
    initialSlide: slideIndex,
    beforeChange: (current, next) => setSlideIndex(next),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          initialSlide: slideIndex,
        },
      },
    ],
  };

  return (
    <IntroContainer>
      <Block>
        <ContentContainer>
          <TitleSection>
            <h1>{heading ? heading : indexPage.headingText}</h1>
          </TitleSection>
        </ContentContainer>
        {categories.nodes.length > 0 && (
          <Categories>
            <StyledSlider {...settings}>
              <CategoryLink
                to={`/our-work/highlights/?q=${slideIndex}`}
                activeClassName="active"
                className={getActiveStatus("highlights") ? "active" : ""}
              >
                Highlights
              </CategoryLink>
              {categories.nodes.map((category, index) => {
                return (
                  <CategoryLink
                    to={`/our-work/${category.slug}/?q=${slideIndex}`}
                    activeClassName="active"
                    className={getActiveStatus(category.slug) ? "active" : ""}
                    key={index}
                  >
                    {category.category}
                  </CategoryLink>
                );
              })}
              <CategoryLink
                to={`/our-work/?q=${slideIndex}`}
                activeClassName="active"
                className={getActiveStatus("all") ? "active" : ""}
              >
                All
              </CategoryLink>
            </StyledSlider>
          </Categories>
        )}
      </Block>
    </IntroContainer>
  );
};

export default IntroBlock;
